<template>
  <getecma-single-content-layout>
    <template #content>
      <getecma-breadcrumb :items="itemsBreadcrumb" class="mt--xl " />
      <div class="d--flex justify-content--space-between align-items--center">
        <getecma-header class="mt--md">Clientes (Total: {{ customers.pagination.total }})</getecma-header>
        <div class="d--flex align-items--center" @keyup.enter="onSearchInput">
          <el-input v-model="searchQuery" placeholder="Buscar cliente" class="input-with-select" clearable @clear="onClearSearch">
            <el-button slot="append" icon="el-icon-search" @click="onSearchInput"></el-button>
          </el-input>
          <getecma-button v-if="isAdmin" style="margin-left: 5%;" @click="onCreateCustomer">
            Adicionar Cliente
          </getecma-button>
        </div>
      </div>
      <div class="d-flex scroll mb--lg justify-content-center" :style="isMinLayout() ? 'height: 50px;':''" @scroll="infinityScroll($event, customers)">
        <div v-if="customers.data.length" class="w-100">
          <div
            v-for="customer in customers.data"
            :key="customer.id"
            class="w-100 mb--md">
            <getecma-customer-card
              :customer="customer" />
          </div>
        </div>
        <getecma-empty-box v-else description="Nenhum cliente encontrado" />
      </div>
    </template>
  </getecma-single-content-layout>
</template>

<script>
import { goToCreateCustomer } from '@/modules/customer/customer.routes';
import { fetchCustomers } from '@/modules/customer/customer.service';
import { getters } from '@/modules/user/user.store';
import { USERS_ROLES } from '@/modules/user/user.constants';
import { toastError } from '@/services/toastService';
import { infinityScroll } from '@/helpers/scroll/infinity';
import { isMinLayout } from '@/helpers/layout/layout';

import GetecmaCustomerCard from '@/modules/customer/components/CustomerCard.vue';
import GetecmaSingleContentLayout from '@/layouts/SingleContentLayout.vue';
import GetecmaBreadcrumb from '@/components/breadcrumb/Breadcrumb.vue';

export default {
  name: 'GetecmaMySpaceCustomers',
  components: {
    GetecmaCustomerCard,
    GetecmaSingleContentLayout,
    GetecmaBreadcrumb,
  },
  data() {
    return {
      itemsBreadcrumb: [
        { name: 'Dashboard', path: '/' },
        { name: 'Clientes', path: this.$route.path },
      ],
      customers: { data: [], pagination: { page: 1, limit: 20, lastPage: 0 } },
      searchQuery: '',
    };
  },
  computed: {
    isAdmin() {
      return getters.getUser().permission === USERS_ROLES.ADMIN;
    },
  },
  mounted() {
    if (localStorage.getItem('searchQueryCustomer') !== null) {
      this.searchQuery = localStorage.getItem('searchQueryCustomer');
    }
    this.fetchCustomers();
  },
  methods: {
    isMinLayout,
    fetchCustomers() {
      this.customers.loading = true;
      fetchCustomers({ limit: this.customers.pagination.limit, page: this.customers.pagination.page, search: this.searchQuery })
        .then(({ customers, total_customers: total }) => {
          if (this.searchQuery === '') {
            this.customers.data.push(...customers);
          } else {
            this.customers.data = customers;
          }
          Object.assign(this.customers.pagination, { total: parseInt(total), lastPage: parseInt((total / this.customers.pagination.limit) + 1) });
        })
        .catch(() => toastError('Erro ao obter clientes.'))
        .finally(() => { this.customers.loading = false; });
    },
    onCreateCustomer() {
      goToCreateCustomer(this.$router);
    },
    infinityScroll(event, content) {
      infinityScroll(event, content)
        .then(() => {
          this.fetchCustomers();
        })
        .catch(() => {});
    },
    onSearchInput() {
      this.customers.pagination.page = 1;
      this.customers.pagination.lastPage = 0;
      this.customers.data = [];
      localStorage.setItem('searchQueryCustomer', this.searchQuery);
      this.fetchCustomers();
    },
    onClearSearch() {
      this.searchQuery = '';
      localStorage.setItem('searchQueryCustomer', '');
      this.onSearchInput();
    },
  },
};
</script>
